<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-text-field
            v-model="filterText"
            style="max-width: 280px"
            append-icon="mdi-magnify"
            label="Search"
            placeholder="By Name"
            :disabled="table.loading"
            color="tertiary"
            clearable
            @keyup.enter="filterByName(false)"
            @click:append="filterByName(false)"
            @click:clear="filterByName(true)"
          />
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :server-items-length="table.totalItems"
          class="elevation-1"
          item-key="id"
          :options.sync="table.options"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr>
              <td>
                <v-avatar :color="row.item.color" size="35">
                  <span class="white--text font-weight-bold">{{ row.item.name.substring(0, 2).toUpperCase() }} </span>
                </v-avatar>
                {{ row.item.name }}
              </td>
              <td>
                {{ $moment(row.item.date).format("LL") }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { HOLIDAYS } from "../query";

  export default {
    name: "HolidayOverview",
    data: (vm) => ({
      filterText: null,
      table: {
        loading: false,
        totalItems: 0,
        options: {
          page: 1,
          sortBy: ["date"],
          sortDesc: [false],
          itemsPerPage: 10
        },
        criteria: {
          name: null
        },
        items: [],
        headers: [
          {
            text: "Name",
            align: "left",
            sortable: true,
            value: "name",
            class: ["primary--text"]
          },
          {
            text: "Date",
            align: "left",
            sortable: true,
            value: "date",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      filterByName(reset) {
        this.table.criteria.name = !reset ? this.filterText : null;
        this.table.options.page = 1;
        this.$nextTick(this.fetchItems);
      },
      fetchItems() {
        this.table.loading = true;
        this.$apollo
          .query({
            query: HOLIDAYS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.toPaginationCriteria(this.table.options)
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.items = data.filterWithPaginateHolidays.content;
              this.table.totalItems = data.filterWithPaginateHolidays.pageInfo.totalElements;
            }
          })
          .catch(console.error)
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>
